import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useFormik } from "formik";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from "react-redux";
import _ from "underscore";
import * as Yup from "yup";
import { setAccountInformation } from "../../../../redux/Account/account.action";
import { resetKeyboard, setInput } from "../../../../redux/Keyboard/keyboard.action";
import BackButton from "../../../shared/BackButton";
import TakePicture2 from "../../../shared/TakePicture2";
import {
  contactObject,
  fetchUnitDataFromReservation,
  locationCode,
  setContactObject,
  setReservationObject,
  unitObject,
} from "../../../Utils/Common";
import { getTenantDetailsByEmail, getTenantProfile } from "../../../Utils/data";
import { logApiFail } from "../../../Utils/rollbar";

const InformationForm = (props) => {
  const dispatch = useDispatch();
  const honeywell = useRef("");
  const formEl = useRef(null);

  const account = contactObject() || {};
  const [submitting, setSubmitting] = useState(false);
  const { currentInput, currentValue } = useSelector((state) => state.keyboard);
  const [showWebCam, setShowWebCam] = useState(false);

  if (unitObject() === null) {
    props.history.push("/");
  }

  const capitalize = (s) => {
    if (s.length === 0) return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const formik = useFormik({
    initialValues: {
      first: account.first || "",
      last: account.last || "",
      email: account.email || "",
      cell_phone: account.cell_phone || "",
      driver_license_number: account.driver_license_number || "",
      company: account.company || "",
      address: account.address || "",
      state: account.state || "",
      city: account.city || "",
      zip: account.zip || "",
      active_military: !!account.active_military,
    },
    onSubmit: (values) => {},
    validationSchema: Yup.object().shape({
      first: Yup.string().required("First Name required"),
      last: Yup.string().required("Last Name required"),
      email: Yup.string().email("Please enter correct Email ").required("Email required"),
      cell_phone: Yup.string()
        .min(10, "Phone number must be 10 digits")
        .max(10, "Phone number must be 10 digits")
        .matches(/\d{10}/, "Phone number must be 10 digits")
        .required("Phone Required"),
      driver_license_number: Yup.string()
        .matches(/^[A-Z0-9a-z-]{3,21}$/, "Incorrect format: DL Number: xxxxxxx")
        .required("Driver's License Number required"),
      company: Yup.string(),
      address: Yup.string().max(140).required("Address Required"),
      state: Yup.string()
        .matches(
          /^(AK|AL|AR|AZ|CA|CO|CT|DC|DE|FL|GA|HI|IA|ID|IL|IN|KS|KY|LA|MA|MD|ME|MI|MN|MO|MS|MT|NB|NC|ND|NH|NJ|NM|NV|NY|OH|OK|OR|PA|RI|SC|SD|TN|TX|UT|VA|VT|WA|WI|WV|WY)$/,
          "State must be valid"
        )
        .required("State Required"),
      city: Yup.string().required("City Required"),
      zip: Yup.string().matches(/\d{5}/, "Zip Code must be 5 digits").required("Zip Required"),
    }),
  });

  useEffect(() => {
    dispatch(
      resetKeyboard([
        "first",
        "last",
        "email",
        "cell_phone",
        "company",
        "address",
        "city",
        "state",
        "zip",
        "submit",
        "driver_license_number",
      ])
    );

    if (account.first && account.last && account.address) {
      // If information was prefilled with scanner
      formik.setTouched({ email: true, cell_phone: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyPress = (e) => {
    console.log(e);
    if (e.target.nodeName === "INPUT") {
      return;
    }

    if (e.key === "Enter") {
      if (honeywell.current !== "") {
        processHoneywell(honeywell.current);
        honeywell.current = "";
      }
    } else if (e.key.length === 1) {
      honeywell.current = honeywell.current + e.key;
    }
  };

  const processHoneywell = (result) => {
    const info = result.split(",");

    const profile = {
      first: info[0],
      last: info[1],
      address: info[2],
      city: info[4],
      state: info[5],
      zip: info[6],
      driver_license_number: info[7],
    };
    formik.setValues(profile);
    setContactObject(profile);
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  });

  useEffect(() => {
    if (currentInput === "state") {
      formik.setFieldValue(currentInput, currentValue.toUpperCase(), true);
    } else if (currentInput === "first" || currentInput === "last" || currentInput === "city") {
      formik.setFieldValue(currentInput, capitalize(currentValue), true);
    } else {
      formik.setFieldValue(currentInput, currentValue, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInput, currentValue]);

  useEffect(() => {
    if (showWebCam) {
      dispatch(resetKeyboard([]));
    }
  }, [dispatch, showWebCam]);

  const handleFocus = (inputName) => {
    const value = formik.values[inputName];
    dispatch(setInput(inputName, value || ""));
    setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 300);
  };

  const handleState = (e) => {
    e.target.value = e.target.value.toUpperCase();
    formik.handleChange(e);
  };

  const handleCity = (e) => {
    e.target.value = capitalize(e.target.value);
    formik.handleChange(e);
  };

  const handleNames = (e) => {
    e.target.value = capitalize(e.target.value);
    formik.handleChange(e);
  };

  const isTenantExisting = async (email) => {
    const tenants = await getTenantDetailsByEmail(email);
    return tenants && tenants.length > 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formik.isValid) {
      formik.setTouched({
        first: true,
        last: true,
        email: true,
        cell_phone: true,
        driver_license_number: true,
        address: true,
        state: true,
        city: true,
        zip: true,
      });
      return;
    }

    const existingTenant = await isTenantExisting(formik.values.email);
    if (existingTenant) {
      const accessible = sessionStorage.getItem("accessible") === "on";
      confirmAlert({
        title: "A tenant with this email address already exists.",
        message: "Do you wish to continue with this tenant account?",
        overlayClassName: accessible ? "accessible" : "",
        buttons: [
          {
            label: "No",
            onClick: () => {},
          },
          {
            label: "Yes",
            onClick: () => {
              toggleWebcam();
            },
          },
        ],
      });
    } else {
      toggleWebcam();
    }
  };

  const toggleWebcam = () => {
    if (process.env.REACT_APP_ENV === "localhost" || process.env.REACT_APP_ENV === "staging") {
      loadTenantAndSubmit();
    } else {
      setShowWebCam(true);
    }
  };

  const createReservation = (contact) => {
    const reservationDate = moment().format("L");
    const unit = unitObject();

    const data = {
      service_name: "SaveReservationDetails_WEB",
      xml_body: {
        strLocationCode: locationCode(),
        strReservationDateTime: reservationDate,
        strDateRequired: reservationDate,
        strUnitTypeId: unit.unitTypeId,
        strFirstName: contact.first,
        strLastName: contact.last,
        strAddress: contact.address,
        strCity: contact.city,
        strStateCode: contact.state,
        strZipCode: contact.zip,
        strEMail: contact.email,
        strHomePhone: contact.cell_phone,
        strBusinessPhone: "",
        strCellPhone: contact.cell_phone,
        strFaxNumber: "",
        strCompanyName: contact.company || "",
        strDiscountIds: unit.discountId,
        strMarketingInfo: "0",
        strCustomerNotes: "Reservation from Kiosk",
        strChkReservThresholdLimit: "1",
        strApplyInternetPrice: "0",
        strRequestOrigin: "W",
        intUserId: "1",
        UnitTypeRent: unit.rent,
      },
    };
    return axios
      .post("/api/ssm", JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
      })
      .then(async (response) => {
        const result = response.data.ReservationResult;

        if (result && result.SaveSuccessful && result.SaveSuccessful[0] === "TRUE") {
          const data = {
            service_name: "UpdateTenantMilitaryStatus",
            xml_body: {
              strLocationCode: locationCode(),
              strTenantID: result.TenantID[0],
              blnMilitary: contact.active_military,
            },
          };
          try {
            await axios.post("/api/ssm", JSON.stringify(data), {
              headers: { "Content-Type": "application/json" },
            });
          } catch (error) {
            console.log(error);
          }
          const reservationResult = {
            reservationCode: result.ReservationCode[0],
            unitNumber: result.UnitNumber[0],
            tenantId: result.TenantID[0],
          };
          setReservationObject(reservationResult);
          dispatch(resetKeyboard([]));

          fetchUnitDataFromReservation();
          props.history.push("/military", { prevPageUrl: "/account-information" });
        } else {
          handleReservationError(result);
        }
      })
      .catch((error) => {
        logApiFail("/api/ssm", data, error);
        confirmAlert({
          title: "Failed to save reservation",
          message: error + "",
          buttons: [
            {
              label: "OK",
              onClick: () => {
                props.history.push("/units");
              },
            },
          ],
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const createReservationWithTenant = (tenant) => {
    const reservationDate = moment().format("L");
    const tenantId = tenant.tenantId;
    const unit = unitObject();

    const data = {
      service_name: "SaveReservationDetailsForExistingTenants",
      xml_body: {
        strLocationCode: locationCode(),
        strTenantID: tenantId,
        strReservationDateTime: reservationDate,
        strDateRequired: reservationDate,
        strUnitTypeId: unit.unitTypeId,
        strMarketingInfo: 0,
        strRequestOrigin: "W",
        intUserId: 1,
        strInquirySourceId: "1",
        strDispositionId: "1",
        strChkReservThresholdLmt: "1",
        strApplyInternetPrice: "0",
        strCustomerNotes: "Reservation from Kiosk",
        strOtherInterestedUnitTypeId1: "",
        strOtherInterestedUnitTypeId2: "",
        strApplyInternetPrice_For_OtherInterestedUnitTypeId1: "",
        strApplyInternetPrice_For_OtherInterestedUnitTypeId2: "",
        strFirstMonthDiscountIds: unit.discountId,
        strAdSourceCallerId: "",
        strAdSourceId: "",
        demographicOptions: "",
        UnitTypeRent: unit.rent,
      },
    };
    return axios
      .post("/api/ssm", JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        const result = response.data.ReservationResult;

        if (result && result.SaveSuccessful && result.SaveSuccessful[0] === "TRUE") {
          const obj = {
            reservationCode: result.ReservationCode[0],
            unitNumber: result.UnitNumber[0],
            tenantId: tenantId,
          };
          setReservationObject(obj);
          dispatch(resetKeyboard([]));

          fetchUnitDataFromReservation();
          props.history.push("/military", { prevPageUrl: "/account-information" });
        } else {
          handleReservationError(result);
        }
      })
      .catch((error) => {
        logApiFail("/api/ssm", data, error);
        confirmAlert({
          title: "Failed to save reservation",
          message: error + "",
          buttons: [
            {
              label: "OK",
              onClick: () => {
                props.history.push("/units");
              },
            },
          ],
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleReservationError = (result) => {
    if (
      result &&
      result.SaveSuccessful &&
      result.SaveSuccessful[0] === "FALSE" &&
      result.ErrorMessage[0] === "Units Not Available"
    ) {
      confirmAlert({
        title: "Failed to save reservation",
        message:
          "We're sorry, the last unit of that type has been rented. Please select another or call us at 833-777-7273 for assistance.",
        buttons: [
          {
            label: "OK",
            onClick: () => {
              props.history.push("/units");
            },
          },
        ],
      });
    } else if (result && result.SaveSuccessful && result.SaveSuccessful[0] === "FALSE" && result.ErrorMessage[0]) {
      confirmAlert({
        title: "Failed to save reservation",
        message: result.ErrorMessage[0],
        buttons: [
          {
            label: "OK",
            onClick: () => {
              props.history.push("/units");
            },
          },
        ],
      });
    }
  };

  const loadTenantAndSubmit = async () => {
    const email = formik.values.email;
    if (!email) {
      window.alert("Email required");
      return;
    }
    setSubmitting(true);
    const existingTenant = await isTenantExisting(email);
    if (existingTenant) {
      const tenants = await getTenantDetailsByEmail(email);
      const myTenant = _.find(tenants, (t) => t.FacilityID[0] !== "1"); // Avoid staging's disabled location(ID: 1)
      const tenantProfile = await getTenantProfile(myTenant.FacilityID[0], myTenant.TenantID[0]);
      setContactObject({
        ...tenantProfile,
        driver_license_number: formik.values.driver_license_number,
      });
      createReservationWithTenant(tenantProfile);
    } else {
      setContactObject(formik.values);
      dispatch(setAccountInformation(formik.values));
      createReservation(formik.values);
    }
  };

  if (showWebCam) {
    return <TakePicture2 isLoading={submitting} onConfirm={loadTenantAndSubmit} />;
  }

  return (
    <React.Fragment>
      <div className="wrapper wrapper-flex">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="text-center">
                <h1 className="font-weight-bold mb-5">
                  Please enter your <br />
                  contact information <br />
                  or scan your ID now
                </h1>
              </div>
            </div>
          </div>
          <form ref={formEl} className="accountInfo" onSubmit={handleSubmit} autoComplete="off">
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <input
                    autoComplete="off"
                    type="text"
                    className={`form-control form-control-lg ${currentInput === "first" ? "focus" : ""}`}
                    placeholder="First Name"
                    name="first"
                    value={formik.values.first}
                    onChange={handleNames}
                    onBlur={formik.handleBlur}
                    onFocus={() => handleFocus("first")}
                  />
                  <span className="text-danger">{formik.touched.first && formik.errors.first}</span>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control form-control-lg ${currentInput === "last" ? "focus" : ""}`}
                    placeholder="Last Name"
                    autoComplete="off"
                    name="last"
                    value={formik.values.last}
                    onChange={handleNames}
                    onBlur={formik.handleBlur}
                    onFocus={() => handleFocus("last")}
                  />
                  <span className="text-danger">{formik.touched.last && formik.errors.last}</span>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control form-control-lg ${currentInput === "email" ? "focus" : ""}`}
                    placeholder="Email Address"
                    autoComplete="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onFocus={() => handleFocus("email")}
                  />
                  <span className="text-danger">{formik.touched.email && formik.errors.email}</span>
                </div>
              </div>

              <div className="col-6">
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control form-control-lg ${currentInput === "cell_phone" ? "focus" : ""}`}
                    placeholder="Phone"
                    autoComplete="cell_phone"
                    name="cell_phone"
                    maxLength="10"
                    value={formik.values.cell_phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onFocus={() => handleFocus("cell_phone")}
                  />
                  <span className="text-danger">{formik.touched.cell_phone && formik.errors.cell_phone}</span>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control form-control-lg ${
                      currentInput === "driver_license_number" ? "focus" : ""
                    }`}
                    placeholder="Driver's License #"
                    name="driver_license_number"
                    maxLength="21"
                    value={formik.values.driver_license_number}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onFocus={() => handleFocus("driver_license_number")}
                  />
                  <span className="text-danger">
                    {formik.touched.driver_license_number && formik.errors.driver_license_number}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control form-control-lg ${currentInput === "company" ? "focus" : ""}`}
                    placeholder="Company Name"
                    autoComplete="company"
                    name="company"
                    value={formik.values.company}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onFocus={() => handleFocus("company")}
                  />
                  <span className="text-danger">{formik.touched.company && formik.errors.company}</span>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control form-control-lg ${currentInput === "address" ? "focus" : ""}`}
                    placeholder="Address"
                    autoComplete="address"
                    name="address"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onFocus={() => handleFocus("address")}
                  />
                  <span className="text-danger">{formik.touched.address && formik.errors.address}</span>
                </div>
              </div>

              <div className="col-5">
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control form-control-lg ${currentInput === "city" ? "focus" : ""}`}
                    placeholder="City"
                    autoComplete="city"
                    name="city"
                    value={formik.values.city}
                    onChange={handleCity}
                    onBlur={formik.handleBlur}
                    onFocus={() => handleFocus("city")}
                  />
                  <span className="text-danger">{formik.touched.city && formik.errors.city}</span>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control form-control-lg ${currentInput === "state" ? "focus" : ""}`}
                    maxLength="2"
                    placeholder="State"
                    autoComplete="state"
                    name="state"
                    value={formik.values.state}
                    onChange={handleState}
                    onBlur={formik.handleBlur}
                    onFocus={() => handleFocus("state")}
                  />
                  <span className="text-danger">{formik.touched.state && formik.errors.state}</span>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control form-control-lg ${currentInput === "zip" ? "focus" : ""}`}
                    placeholder="Zip"
                    autoComplete="zip"
                    name="zip"
                    maxLength="5"
                    value={formik.values.zip}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onFocus={() => handleFocus("zip")}
                  />
                  <span className="text-danger">{formik.touched.zip && formik.errors.zip}</span>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <div className="form-group">
                  <input
                    type="checkbox"
                    className="ml-2"
                    style={{ transform: "scale(1.5)" }}
                    id="active_military"
                    name="active_military"
                    checked={formik.values.active_military}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <label className="form-check-label ml-3" htmlFor="active_military">
                    Active Military
                  </label>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 text-right">
                <button
                  name="submit"
                  type="submit"
                  disabled={submitting || formik.isSubmitting}
                  className={`btn btn-pink btn-lg letter-spacing-2 ${currentInput === "submit" ? "focus" : ""}`}
                >
                  {submitting ? "Submitting" : "Next"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <BackButton backPath="/climate-control" />
    </React.Fragment>
  );
};

export default InformationForm;
