import CryptoJS from "crypto-js";
import moment from "moment-timezone";
import axios from "axios";
import { addTenantNotes } from "./data";
// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem("token") || null;
};

export const amountDue = () => {
  let moveInCost = moveInCostObject();

  const total =
    parseFloat(moveInCost.RentDetails.TotalRentAmount) +
    parseFloat(moveInCost.InsuranceDetails.InsuranceAmount) +
    parseFloat(moveInCost.FeeDetails.FeesAmount) -
    parseFloat(moveInCost.DiscountDetails.DiscountValue) +
    taxSum();

  return total.toFixed(2);
};

const ENCRYPT_KEY = "ENCRYPT_KEY_2020"

export const finalDiscountId = () => {
  const unitInfo = unitObject();
  const firstMonthFree = unitInfo.discountName === "First Month Free!" || unitInfo.discountAmount === "100.00"

  const autopay = autopayBit() === "1"

  return firstMonthFree && !autopay ? "0" : unitInfo.discountId;
}

export const formatCurrency = (amount) => {
  if (isNaN(amount)) return amount;
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return formatter.format(amount);
};

export const taxSum = () => {
  let moveInCost = moveInCostObject();
  return (
    parseFloat(moveInCost.RentDetails.TaxOnRent) +
    parseFloat(moveInCost.InsuranceDetails.TaxOnInsurance) +
    parseFloat(moveInCost.FeeDetails.TaxOnFees)
  );
}

const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const isSequentialIncrease = (number) => {
  if (!number || isNaN(number)) {
    return true;
  }
  const strNum = number + "";
  const isSequentialIncrease = true;
  for (let i = 1; i < strNum.length; ++i) {
    if (strNum.charAt(i) <= strNum.charAt(i - 1)) {
      return false;
    }
  }
  return isSequentialIncrease;
};

const isSequentialDecrease = (number) => {
  if (!number || isNaN(number)) {
    return true;
  }
  const strNum = number + "";
  const isSequentialDecrease = true;
  for (let i = 1; i < strNum.length; ++i) {
    if (strNum.charAt(i) >= strNum.charAt(i - 1)) {
      return false;
    }
  }
  return isSequentialDecrease;
};

export const getRandomIntWithoutSequential = (min = 100001, max = 999998) => {
  let int = getRandomInt(min, max);
  while (isSequentialIncrease(int) || isSequentialDecrease(int)) {
    int = getRandomInt(min, max);
  }
  return int;
};

export const generateGateCode = () => {
  return `${getRandomIntWithoutSequential()}`;
};

export const setGateCode = (gateCode) => {
  sessionStorage.setItem("gate_code", CryptoJS.AES.encrypt(gateCode, ENCRYPT_KEY).toString());
};

export const getGateCode = () => {
  return (
    sessionStorage.getItem("gate_code") &&
    CryptoJS.AES.decrypt(sessionStorage.getItem("gate_code"), ENCRYPT_KEY).toString(CryptoJS.enc.Utf8)
  );
};

export const setTenantLeaseStatus = (object) => {
  sessionStorage.setItem("tenant_lease_status", CryptoJS.AES.encrypt(JSON.stringify(object), ENCRYPT_KEY).toString());
};

export const getTenantLeaseStatus = () => {
  return (
    sessionStorage.getItem("tenant_lease_status") &&
    JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem("tenant_lease_status"), ENCRYPT_KEY).toString(CryptoJS.enc.Utf8))
  );
};

export const setLocationObject = (object) => {
  localStorage.setItem("location_object", CryptoJS.AES.encrypt(JSON.stringify(object), ENCRYPT_KEY).toString());
};

export const locationObject = () => {
  return (
    localStorage.getItem("location_object") &&
    JSON.parse(
      CryptoJS.AES.decrypt(localStorage.getItem("location_object"), ENCRYPT_KEY).toString(CryptoJS.enc.Utf8)
    )
  );
};

export const locationCode = () => {
  return locationObject().location_code;
};

export const setUnitObject = (object) => {
  sessionStorage.setItem("unit_object", JSON.stringify(object));
};

export const unitObject = () => {
  return sessionStorage.getItem("unit_object") && JSON.parse(sessionStorage.getItem("unit_object"));
};

export const setReservationObject = (object) => {
  sessionStorage.setItem(
    "reservation_object",
    CryptoJS.AES.encrypt(JSON.stringify(object), "reservation_object").toString()
  );
};

export const reservationObject = () => {
  return (
    sessionStorage.getItem("reservation_object") &&
    JSON.parse(
      CryptoJS.AES.decrypt(sessionStorage.getItem("reservation_object"), "reservation_object").toString(
        CryptoJS.enc.Utf8
      )
    )
  );
};

export const setReservedUnitObject = (object) => {
  sessionStorage.setItem("reserved_unit_object", JSON.stringify(object));
};

export const reservedUnitObject = () => {
  return sessionStorage.getItem("reserved_unit_object") && JSON.parse(sessionStorage.getItem("reserved_unit_object"));
};

export const setMoveInCostDataObject = (object) => {
  sessionStorage.setItem("move_in_cost_data_object", JSON.stringify(object));
};

export const moveInCostObject = () => {
  return sessionStorage.getItem("move_in_cost_data_object") && JSON.parse(sessionStorage.getItem("move_in_cost_data_object"));
}

export const setContactObject = (object) => {
  sessionStorage.setItem("contact_object", JSON.stringify(object));
};

export const contactObject = () => {
  return sessionStorage.getItem("contact_object") && JSON.parse(sessionStorage.getItem("contact_object"));
};

export const setSessionObject = (object) => {
  sessionStorage.setItem("session_object", JSON.stringify(object));
};

export const sessionObject = () => {
  return sessionStorage.getItem("session_object") && JSON.parse(sessionStorage.getItem("session_object"));
};

export const tenantId = () => sessionObject().id;

export const setInsuranceId = (value) => {
  sessionStorage.setItem("insurance_id", CryptoJS.AES.encrypt(value, "insurance_id").toString());
};

export const insuranceId = () => {
  return (
    sessionStorage.getItem("insurance_id") &&
    CryptoJS.AES.decrypt(sessionStorage.getItem("insurance_id"), "insurance_id").toString(CryptoJS.enc.Utf8)
  );
};

export const setInsuranceStatus = (statusId) => {
  sessionStorage.setItem("insurance_status", statusId);
};

export const getInsuranceStatus = () => {
  return sessionStorage.getItem("insurance_status") || "0";
};

export const setInsuranceType = (type) => {
  sessionStorage.setItem("insurance_type", type);
};

export const getInsuranceType = () => {
  return sessionStorage.getItem("insurance_type") || "Insurance";
};

export const setTenantNote = (value) => {
  sessionStorage.setItem("tenant_note", value);
};

export const tenantNote = () => {
  return sessionStorage.getItem("tenant_note");
};

export const setTenantProfile = (value) => {
  sessionStorage.setItem("tenant_profile", JSON.stringify(value));
};

export const tenantProfile = () => {
  return sessionStorage.getItem("tenant_profile") && JSON.parse(sessionStorage.getItem("tenant_profile"));
};

export const purgeTenantNote = () => {
  sessionStorage.removeItem("tenant_note");
};

export const setInsuranceCoverage = (value) => {
  sessionStorage.setItem("insurance_coverage", value);
};

export const insuranceCoverage = () => {
  return sessionStorage.getItem("insurance_coverage") ? parseInt(sessionStorage.getItem("insurance_coverage")) : 0;
};

export const setLienHolderInfo = (object) => {
  sessionStorage.setItem("lien_holder", CryptoJS.AES.encrypt(JSON.stringify(object), ENCRYPT_KEY).toString());
};

export const getLienHolderInfo = () => {
  return (
    sessionStorage.getItem("lien_holder") &&
    JSON.parse(
      CryptoJS.AES.decrypt(sessionStorage.getItem("lien_holder"), ENCRYPT_KEY).toString(CryptoJS.enc.Utf8)
    )
  );
};

export const setMilitaryInfo = (object) => {
  sessionStorage.setItem("military_info", CryptoJS.AES.encrypt(JSON.stringify(object), ENCRYPT_KEY).toString());
};

export const getMilitaryInfo = () => {
  return (
    sessionStorage.getItem("military_info") &&
    JSON.parse(
      CryptoJS.AES.decrypt(sessionStorage.getItem("military_info"), ENCRYPT_KEY).toString(CryptoJS.enc.Utf8)
    )
  );
};

export const setPendingPaymentObject = (object) => {
  sessionStorage.setItem("pending_payment", JSON.stringify(object));
};

export const pendingPaymentObject = () => {
  return sessionStorage.getItem("pending_payment") && JSON.parse(sessionStorage.getItem("pending_payment"));
};

export const getTenantLease = () => {
  return sessionStorage.getItem("tenant_lease") && JSON.parse(sessionStorage.getItem("tenant_lease"));
};

export const setTenantLease = (object) => {
  sessionStorage.setItem("tenant_lease", JSON.stringify(object));
};

export const set24HourFee = (object) => {
  sessionStorage.setItem("24_hour_access_fee", CryptoJS.AES.encrypt(JSON.stringify(object), ENCRYPT_KEY).toString());
};

export const get24HourFee = () => {
  return (
    sessionStorage.getItem("24_hour_access_fee") &&
    JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem("24_hour_access_fee"), ENCRYPT_KEY).toString(CryptoJS.enc.Utf8))
  );
};

export const fullDayAccessPath = () => {
  if (localStorage.getItem("24_hours_fee_enabled") === "true") {
    return "/full-day-access";
  } else {
    return "/lease-summary";
  }
}

export const setAutopayBit = (value) => {
  sessionStorage.setItem("autopay_bit", value);
};

export const autopayBit = () => {
  return sessionStorage.getItem("autopay_bit") || "0";
};

export const purgeNewTenantData = () => {
  sessionStorage.removeItem("contact_object");
  sessionStorage.removeItem("move_in_cost_data_object");
  sessionStorage.removeItem("unit_object");
  sessionStorage.removeItem("reservation_object");
  sessionStorage.removeItem("reserved_unit_object");
  sessionStorage.removeItem("insurance_id");
  sessionStorage.removeItem("insurance_status");
  sessionStorage.removeItem("climate_control");
  sessionStorage.removeItem("created_lease_number");
  sessionStorage.removeItem("tenant_note");
  sessionStorage.removeItem("autopay_bit");
  sessionStorage.removeItem("lease_pdf_signed_data");
  sessionStorage.removeItem("customer_info");
  sessionStorage.removeItem("lien_holder");
  sessionStorage.removeItem("military_info");
};

export const intUserId = () => {
  return 1; // placeholder
};

export const cardType = (cardBrand, shortBrand) => {
  switch (cardBrand.toLowerCase()) {
    case "visa":
      return "VI";
    case "mastercard":
      return "MC";
    case "american express":
      return "AX";
    case "discover":
      return "DC";
    default:
      return shortBrand || "VI";
  }
};

export function getXmlAttribute(xmlObject, key) {
  const arr = (xmlObject[0] || xmlObject)[key];

  if (!arr || arr.length < 1) return null;

  return arr[0].replace(/\n/gi, " ").trim();
}

export const getPostResult = () => {
  const paramsString = decodeURIComponent(window.location.search);
  const action = (/action=(\w*)/.exec(paramsString) || [])[1];
  const orderId = (/<ORDERID>(\d*)<\/ORDERID>/.exec(paramsString) || [])[1];
  const responseCode = (/<RESPONSECODE>(\w*)<\/RESPONSECODE>/.exec(paramsString) || [])[1];
  const responseDescription = (/<RESPONSEDESCRIPTION>(.*)<\/RESPONSEDESCRIPTION>/.exec(paramsString) || [])[1] || "";

  return {
    action,
    orderId,
    responseCode,
    responseDescription: responseDescription.replace(/\+/g, " "),
  };
};

export const setLeasePdfSignedData = (object) => {
  sessionStorage.setItem(
    "lease_pdf_signed_data",
    CryptoJS.AES.encrypt(JSON.stringify(object), ENCRYPT_KEY).toString()
  );
};

export const getLeasePdfSignedData = () => {
  return (
    sessionStorage.getItem("lease_pdf_signed_data") &&
    JSON.parse(
      CryptoJS.AES.decrypt(sessionStorage.getItem("lease_pdf_signed_data"), ENCRYPT_KEY).toString(
        CryptoJS.enc.Utf8
      )
    )
  );
};

export const getHandlerFocus = (dispatch, setInput) => {
  return (inputName, e) => {
    dispatch(setInput(inputName, e.target.value));
    setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 300);
  };
};

export const convertLocalDateStrToDateMoment = (dateStr, timeZone = "America/Cancun") => {
  return moment(dateStr).tz(timeZone);
};

export const finishExistingTenantNote = async (tenantId, paymentMethod) => {
  const message = `Successful Kiosk payment - ${paymentMethod}`;

  await addTenantNotes(locationCode(), tenantId, message);
};

export const finishNewTenantNote = async (tenantId, paymentMethod) => {
  const contactObj = contactObject();
  const notes = [];
  const tenantNotes = sessionStorage.getItem("tenant_note");
  if (tenantNotes) {
    notes.push(tenantNotes);
  }
  if (contactObj.driver_license_number) {
    const dl = contactObj.driver_license_number;
    notes.push(`Driver's License Number ${dl.substring(dl.length - 4)}`);
  }
  if (paymentMethod) {
    notes.push(`Successful Kiosk payment - ${paymentMethod}`);
    notes.push("Move-In - Kiosk");
  }

  await addTenantNotes(locationCode(), tenantId, notes.join(", "));
};

export const translateFailureMessage = (failureMessage) => {
  let reasonMessage = "Unknown Reason";

  if (failureMessage.toLowerCase().includes("expired") || failureMessage.toLowerCase().includes("expiration")) {
    reasonMessage = "Credit Card Expired";
  } else if (failureMessage.toLowerCase().includes("declined")) {
    reasonMessage = "Declined by Issuer";
  } else {
    let reason = (failureMessage.split("Response : ")[1] || "").trim() || failureMessage || "Unknown Reason";
    reasonMessage = reason.split("|")[0];
    reasonMessage = "" + reason;
  }
  return "Unable to process the Payment: " + reasonMessage;
};

export const addPaymentFailureMessage = async (tenantId) => {
  const message = sessionStorage.getItem("payment_failure_message") || "";
  const shortMessage = translateFailureMessage(message);

  await addTenantNotes(locationCode(), tenantId, shortMessage);
};

export const getVehiclePath = () => {
  const unit = unitObject();
  if (
    unit.categoryName === "Parking" ||
    unit.categoryName === "Covered Parking" ||
    unit.categoryName === "Enclosed Parking"
  ) {
    return "/vehicle-info";
  } else {
    return "/vehicle";
  }

}

export const navigateToVehicleScreen = (props) => {
  const nextPath = getVehiclePath();
  props.history.push(nextPath);
};

export const fetchUnitDataFromReservation = () => {
  // TODO: investigate if this api endpoint is correct.
  const data = {
    location_code: locationCode(),
  };
  axios
    .post("/api/ssm/GetAllUnits", JSON.stringify(data), {
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => {
      const unitNo = reservationObject().unitNumber;
      const unitItem = response.data.SSM.Record.find((item) => unitNo === item.UNITEXTERNALID[0]);
      if (unitItem) {
        const unitData = {
          rent: unitItem.Rent[0],
          uId: unitItem.UNITINTERNALID[0],
          eTypeId: unitItem.UNITTYPEEXTERNALID[0],
          uTypeId: unitItem.UNITTYPEINTERNALID[0],
        };
        setReservedUnitObject(unitData);
      }
    });
};

export const getPrevPageUrl = (key, props) => {
  const { prevPageUrl } = props.location?.state || {};
  if (!prevPageUrl && sessionStorage.getItem(`${key}PrevPageUrl`)) {
    return sessionStorage.getItem(`${key}PrevPageUrl`);
  }
  if (prevPageUrl && prevPageUrl !== sessionStorage.getItem(`${key}PrevPageUrl`)) {
    sessionStorage.setItem(`${key}PrevPageUrl`, prevPageUrl);
  }
  return prevPageUrl;
};
